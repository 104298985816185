/* eslint-disable import/no-mutable-exports */
export const environments = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;
  let environment = environments.STAGING;
  if (hostname === 'localhost') {
    environment = environments.DEVELOPMENT;
  } else if (hostname === 'app.twinelabs.com' || hostname === 'app.knoetic.com') {
    environment = environments.PRODUCTION;
  }

  return environment;
};

// initialize environment
let THIS_DOMAIN = 'http://localhost:3000';
let NODE_ROOT = 'http://localhost:8080/v1/community';
let GRAPHQL_ROOT = 'http://localhost:8080/v1/community/graphql';
let GRAPHQL_SUBSCRIPTION_ROOT = 'ws://localhost:8080/v1/community/graphql';
let GENIE_ROOT = 'http://localhost:8080/v1/genie';
const AGENTS_ROOT = 'http://localhost:8002';
let GENIE_3_ROOT = 'http://localhost:8080/v1/genie3';
let API_ROOT = 'http://localhost:8001/v3/kb';
let AUTH0_DOMAIN = 'knoetic-dev.us.auth0.com';
let AUTH0_CLIENT_ID = 'So38ecMDeDYfAZQckSQEUlDvv7OZyHEH';
let AUTH0_AUDIENCE = 'http://localhost:8000';
let LAUNCHDARKLY_CLIENT_ID = '62faef49a9b551119fe84b9f';
let CUBE_API_ROOT = 'http://localhost:4000/cubejs-api/v1';
let CUBE_CLOUD_API_ROOT = 'http://localhost:4001/cubejs-api/v1';
let CONFIG_SERVICE_ROOT = 'http://localhost:9000';
const YOUTUBE_DATA_API_KEY = 'AIzaSyCwtcUtLwEAczyszjga2BrfH5gVHpn8RJg';
const FULL_STORY_PROPERTIES = {
  orgId: 'BKN28',
  host: 'fsrelay.knoetic.com',
  script: 'fsrelay.knoetic.com/s/fs.js',
};

const POST_HOG_ROOT: { PUBLIC_KEY: string; PUBLIC_HOST: string } = {
  PUBLIC_KEY: 'phc_SE8YtG09VrbrKMkkJF4PSVFuOAO4Be3x5bWy3ReeaF4',
  PUBLIC_HOST: 'https://ph.knoetic.com',
};

const environment = getEnvironment();
if (environment === environments.PRODUCTION) {
  THIS_DOMAIN = 'https://staging.app.knoetic.com';
  API_ROOT = 'https://api.knoetic.com/v3/kb';
  CONFIG_SERVICE_ROOT = 'https://api.knoetic.com';
  NODE_ROOT = 'https://api.knoetic.com/v1/community';
  GRAPHQL_ROOT = 'https://api.knoetic.com/v1/community/graphql';
  GRAPHQL_SUBSCRIPTION_ROOT = 'ws://api.knoetic.com/v1/community/graphql';
  GENIE_ROOT = 'https://api.knoetic.com/v1/genie';
  GENIE_3_ROOT = 'https://api.knoetic.com/v1/genie3';
  AUTH0_DOMAIN = 'login.knoetic.com';
  AUTH0_CLIENT_ID = 'opweN5fjcUiF4LMCZEYmJN7457jD0Bao';
  AUTH0_AUDIENCE = `https://api.twinelabs.dev`;
  LAUNCHDARKLY_CLIENT_ID = '62faef366bf2d011ee66d0c6';
  CUBE_API_ROOT = 'https://api.knoetic.com/cubejs-api/v1';
  CUBE_CLOUD_API_ROOT = 'https://cube.knoetic.com/cubejs-api/v1';
} else if (environment === environments.STAGING) {
  THIS_DOMAIN = 'https://app.knoetic.com';
  API_ROOT = 'https://api.stg.knoetic.com/v3/kb';
  CONFIG_SERVICE_ROOT = 'https://api.stg.knoetic.com';
  GRAPHQL_ROOT = 'https://api.stg.knoetic.com/v1/community/graphql';
  GRAPHQL_SUBSCRIPTION_ROOT = 'ws://api.stg.knoetic.com/v1/community/graphql';
  NODE_ROOT = 'https://api.stg.knoetic.com/v1/community';
  GENIE_ROOT = 'https://api.stg.knoetic.com/v1/genie';
  GENIE_3_ROOT = 'https://api.stg.knoetic.com/v1/genie3';
  AUTH0_DOMAIN = 'login-stg.knoetic.com';
  AUTH0_CLIENT_ID = 'uHOAbkqH599iToZ35H73oKZW3OGgpiB9';
  AUTH0_AUDIENCE = 'https://api.stg.twinelabs.dev';
  LAUNCHDARKLY_CLIENT_ID = '62faef366bf2d011ee66d0c5';
  CUBE_API_ROOT = 'https://api.stg.knoetic.com/cubejs-api/v1';
  CUBE_CLOUD_API_ROOT = 'https://cube.stg.knoetic.com/cubejs-api/v1';
}

export {
  THIS_DOMAIN,
  AGENTS_ROOT,
  API_ROOT,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  CONFIG_SERVICE_ROOT,
  CUBE_API_ROOT,
  CUBE_CLOUD_API_ROOT,
  FULL_STORY_PROPERTIES,
  GENIE_ROOT,
  GRAPHQL_ROOT,
  GRAPHQL_SUBSCRIPTION_ROOT,
  LAUNCHDARKLY_CLIENT_ID,
  NODE_ROOT,
  YOUTUBE_DATA_API_KEY,
  GENIE_3_ROOT,
  POST_HOG_ROOT,
};
