import { ReactNode } from 'react';
import React from 'react';

import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { environments, getEnvironment, POST_HOG_ROOT } from 'config';

export const PostHog = ({ children }: { children: ReactNode }) => {
  if (getEnvironment() !== environments.PRODUCTION) {
    return <>{children}</>;
  }

  const postHogOptions: Partial<PostHogConfig> = {
    api_host: POST_HOG_ROOT.PUBLIC_HOST,
    capture_pageview: false,
    capture_pageleave: false,
    disable_persistence: true,
  };

  return (
    <PostHogProvider apiKey={POST_HOG_ROOT.PUBLIC_KEY} options={postHogOptions}>
      {children}
    </PostHogProvider>
  );
};
